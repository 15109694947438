import { AxiosError } from 'axios';
import { Alert, Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ControlledInput } from '@/components/ControlledInput';
import { toastError, toastSuccess } from '@/components/Toastify';
import useRecaptcha from '@/hooks/useRecaptcha';
import { ResendOtpButton } from '@/pages/auth/authentication/components/ResendOtpButton';
import useVerifyAuthenticateMutation from '@/pages/auth/authentication/hooks/useVerifyAuthenticateMutation';
import {
  AuthenticationFormData,
  AuthenticationPayload,
  MfaErrorCode,
} from '@/pages/auth/authentication/types';
import { AuthError, AuthErrorData } from '@/pages/auth/types';
import { parse } from '@/pages/auth/utils/errorParser';
import { LocalStorageExpiry } from '@/pages/auth/utils/LocalStorageExpiry';
import { removeMfaToken, setCredentials, setMfaToken } from '@/utils/cookies';

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME !== 'production';

export const AuthenticationForm = () => {
  const localStorage = new LocalStorageExpiry();

  const navigate = useNavigate();
  const { mutateAsync: verifyMutateAsync, isPending } = useVerifyAuthenticateMutation();

  const { control, handleSubmit } = useForm<AuthenticationFormData>();
  const [apiError, setApiError] = useState<AuthError | undefined>();

  const { recaptchaToken: recaptchaTokenV3, fetchAndSetRecaptcha } = useRecaptcha();
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleError = (data: AuthError) => {
    fetchAndSetRecaptcha();
    if (data.code) {
      switch (data.code) {
        case MfaErrorCode.INVALID_CODE: {
          // refresh mfaToken
          if (data.mfaToken) {
            setMfaToken(data.mfaToken);
          }
          setApiError({
            ...data,
            message: 'The code you entered is wrong or expired. Try again or resend the code.',
          });
          break;
        }
        case MfaErrorCode.FAILED:
        case MfaErrorCode.INVALID_MFA_TOKEN: {
          navigate('/login', { state: { error: data } });
          break;
        }
        case MfaErrorCode.INVALID_V3_TOKEN: {
          setApiError(data);
          break;
        }
        case MfaErrorCode.INVALID_V2_TOKEN: {
          const error = {
            ...data,
            message:
              '"Unable to verify your request. Please try verifying the recaptcha again and if the issue persists, contact support at support@easyship.com."',
          };
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setRecaptchaTokenV2(null);
          }
          setApiError(error);
          break;
        }
        case MfaErrorCode.INVALID_REQUEST: {
          // need to have customized message since the message passed from BE is default message
          const error = {
            ...data,
            message: 'The request body is malformed or not valid. Please try again',
          };
          setApiError(error);
          break;
        }
        default: {
          toastError();
        }
      }
    } else {
      toastError();
    }
  };

  const handleOnSubmit: SubmitHandler<AuthenticationFormData> = (data) => {
    setApiError(undefined);

    const MFA_TOKEN = Cookies.get('mfaToken');

    const payload: AuthenticationPayload = {
      code: data.code,
      mfaToken: MFA_TOKEN ?? '',
      recaptchaToken: recaptchaTokenV2 ?? recaptchaTokenV3,
      recaptchaFallback: !!recaptchaTokenV2,
    };

    verifyMutateAsync(payload, {
      onSuccess: (data) => {
        setCredentials(data.session.token);
        // remove all of the data in localStorage & cookie (mfaToken) on verify success, not needed anymore
        localStorage.remove('expiresAt');
        localStorage.remove('resendAllowedAt');
        removeMfaToken();

        toastSuccess('Success verifying your account. Redirecting...');
        setTimeout(() => {
          window.location.replace(
            `https://app${IS_DEVELOPMENT ? `.${import.meta.env.VITE_ENV_NAME}` : ''}.easyship.com/dashboard`,
          );
        }, 500);
      },
    }).catch((err: AxiosError<AuthErrorData>) => {
      if (err.response?.data) {
        const error = parse(err.response.data);
        handleError(error);
      } else {
        toastError();
      }
    });
  };

  const onRecaptchaV2Change = (token: string | null) => {
    if (token) {
      setRecaptchaTokenV2(token);
    }
  };

  return (
    <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(handleOnSubmit)}>
      <ControlledInput
        label="Verification Code"
        control={control}
        name="code"
        rules={{
          minLength: { value: 6, message: 'The code must be 6 digits.' },
          maxLength: { value: 6, message: 'The code must be 6 digits.' },
          required: { value: true, message: 'This field is required.' },
        }}
      />
      {/* Wait until recaptcha Token is initialized */}
      {recaptchaTokenV3 && (
        <ResendOtpButton
          onSendOtpFailed={fetchAndSetRecaptcha}
          onSendOtpSucceed={fetchAndSetRecaptcha}
          recaptchaToken={recaptchaTokenV3}
        />
      )}
      {/* Only shows when it returned error from API */}
      {apiError && (
        <Alert severity="error" className="w-full">
          {apiError.message}
        </Alert>
      )}
      <Button
        loading={isPending}
        disabled={
          isPending ||
          ((apiError?.code === MfaErrorCode.INVALID_V3_TOKEN ||
            apiError?.code === MfaErrorCode.INVALID_V2_TOKEN) &&
            !recaptchaTokenV2)
        }
        className="px-10 disabled:bg-sky-500"
        type="submit"
        color="primary"
      >
        Complete Login
      </Button>
      {apiError?.code === MfaErrorCode.INVALID_V3_TOKEN && (
        <ReCAPTCHA
          ref={recaptchaRef}
          className="flex items-center justify-center w-full mt-2"
          sitekey={import.meta.env.VITE_KEY_RECAPTCHA2}
          onChange={onRecaptchaV2Change}
        />
      )}
    </form>
  );
};
