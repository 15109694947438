export const API = {
  environment: import.meta.env.VITE_ENV_NAME,
  endpoint: import.meta.env.VITE_APP_ENDPOINT,
  baseEndpoint: import.meta.env.VITE_API_BASE_URL,
  baseAuthEndpoint: import.meta.env.VITE_AUTH_BASE_URL,
  help: import.meta.env.VITE_APP_HELP,
  credentials: import.meta.env.VITE_APP_CREDENTIALS,
  sentryAuthToken: import.meta.env.SENTRY_AUTH_TOKEN,
  recaptcha: import.meta.env.VITE_KEY_RECAPTCHA,
  recaptcha2: import.meta.env.VITE_KEY_RECAPTCHA2,
  fingerprint: import.meta.env.VITE_KEY_FINGERPRINT,
};
