import * as Sentry from '@sentry/browser';

import { API } from './utils/apiConstants.ts';

const PROXY_URL = `https://easyship.com/o0yliAk3bHXd4tat/5kaYqx1UYeiAaO6f?apiKey=${API.fingerprint ?? ''}`;
const ES_ENDPOINT = 'https://easyship.com/o0yliAk3bHXd4tat/qsH7aLn7CNZFkVQa';

let fp = null;

async function initFingerprint() {
  if (fp) {
    return;
  }

  try {
    const FingerprintJS = await import(PROXY_URL);

    fp = await FingerprintJS.load({
      endpoint: [ES_ENDPOINT, FingerprintJS.defaultEndpoint],
    });
  } catch (error) {
    Sentry.captureMessage(`Auth Fingerprint - Failed to load`, 'error');
  }
}

async function getVisitorResult(id, action, companyName) {
  try {
    if (!fp && API.fingerprint) {
      await initFingerprint();
    }

    const visitor = await fp.get({
      linkedId: id,
      tag: {
        environment: API.environment,
        trigger_action: action,
        company_name: companyName ?? '',
      },
    });
    return visitor;
  } catch (error) {
    Sentry.captureMessage(`Auth Fingerprint - Error getting visitor result: ${error}`, 'error');
    return error;
  }
}

export { initFingerprint, getVisitorResult };
