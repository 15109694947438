import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import '@/index.css';

import App from '@/App';
import { Toastify } from '@/components/Toastify';
import { GatewayProvider } from '@/contexts/gateways';
import { AuthPage } from '@/pages/auth';
import { AuthenticationPage } from '@/pages/auth/authentication';
import { LoginPage } from '@/pages/auth/login';
import { ResetPasswordPage } from '@/pages/auth/reset-password';
import QueryClientProvider from '@/QueryClientProvider';
import { theme } from '@/theme';

import '@/gateways/api/axiosSetup';
import '@/gateways/api/axiosRequestInterceptors';
import { AuthGatewayProvider } from './pages/auth/contexts/AuthGatewayProviders';
import { RedirectLogin } from './pages/auth/redirect-login';
import { ErrorPage } from './pages/oauth/ErrorPage/ErrorPage';
import { API } from './utils/apiConstants';

const isProd = API.environment === 'production';
const credentialsEnv = API.credentials;

Sentry.init({
  enabled: isProd,
  environment: import.meta.env.MODE,
  dsn: 'https://32c44be4c2775081cc2893d1c3a14111@o177970.ingest.us.sentry.io/4507253747875840',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_API_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const routes = createBrowserRouter([
  {
    path: '',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'oauth2/authorize',
        element: <App />,
      },
      {
        path: '/',
        element: <AuthPage />,
        children: [
          {
            index: true,
            loader: async () => redirect('/login'),
          },
          {
            path: 'login',
            element: <LoginPage />,
            loader: () => {
              if (Cookies.get(credentialsEnv)) {
                window.location.replace(
                  `https://app${isProd ? '' : '.' + API.environment}.easyship.com/dashboard`,
                );
                return false;
              }
              return true;
            },
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
          {
            path: 'authenticate',
            element: <AuthenticationPage />,
          },
          {
            path: 'redirect',
            element: (
              <AuthGatewayProvider>
                <RedirectLogin />
              </AuthGatewayProvider>
            ),
          },
          {
            path: 'locked',
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <Navigate to={'/error'} replace />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_KEY_RECAPTCHA ?? ''}>
        <GatewayProvider>
          <QueryClientProvider>
            <Toastify />
            <RouterProvider router={routes} />
          </QueryClientProvider>
        </GatewayProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
