import { useMutation } from '@tanstack/react-query';

import { useAuthGateways } from '@/pages/auth/contexts/AuthGatewayProviders';
import { RedirectPayload } from '@/pages/auth/redirect-login/types';

export default function useRedirectMutation() {
  const { authGateway } = useAuthGateways();

  return useMutation({
    mutationFn: (payload: RedirectPayload) => authGateway.redirect(payload),
  });
}
