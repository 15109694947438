import axios, { AxiosResponse } from 'axios';

import { PublicUser } from '@/core/models/User';
import { AuthenticationPayload } from '@/pages/auth/authentication/types';
import { LoginPayload } from '@/pages/auth/login/types';
import { AuthGateway } from '@/pages/auth/ports/auth';
import { RedirectPayload } from '@/pages/auth/redirect-login/types';
import { EmailOtp, EmailOtpData, SendEmailOtpPayload, SessionToken } from '@/pages/auth/types';
import { API } from '@/utils/apiConstants';
import { camelToSnakeCase, snakeToCamelCase } from '@/utils/caseTransformer';
import { setCredentials, setRedirectBackUrl } from '@/utils/cookies';

// gateway only for /auth API endpoint
export function authGateway(): AuthGateway {
  function login(payload: LoginPayload): Promise<PublicUser> {
    return axios
      .post('/login', camelToSnakeCase(payload), {
        baseURL: `${API.baseAuthEndpoint}`,
      })
      .then(({ data }: AxiosResponse) => snakeToCamelCase(data));
  }

  function redirect(payload: RedirectPayload): Promise<void> {
    return axios
      .post('/sessions/redirect_log_in', payload, {
        baseURL: `${API.baseEndpoint}/api/v1`,
      })
      .then(({ data }: AxiosResponse) => {
        if (!data.session.session_token) {
          throw new Error('API did not return a session token');
        }

        setCredentials(data.session.session_token);
        setRedirectBackUrl(data.redirect_back_url);

        window.location.replace(data.redirect_url);
      });
  }

  function verifyAuthenticate(payload: AuthenticationPayload): Promise<SessionToken> {
    return axios
      .post('/login/verify', camelToSnakeCase(payload), {
        baseURL: `${API.baseAuthEndpoint}`,
      })
      .then(({ data }: AxiosResponse) => snakeToCamelCase(data));
  }

  function sendEmailOtp(payload: SendEmailOtpPayload): Promise<EmailOtp> {
    return axios
      .post('/mfa/email_otp', camelToSnakeCase(payload), {
        baseURL: `${API.baseAuthEndpoint}`,
      })
      .then(({ data }: AxiosResponse<EmailOtpData>) => snakeToCamelCase(data.email_otp));
  }

  return { login, redirect, verifyAuthenticate, sendEmailOtp };
}
