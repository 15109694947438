import { Button } from 'easyship-components';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ControlledInput } from '@/components/ControlledInput';
import { toastError, toastInfo } from '@/components/Toastify';
import { ResetPasswordParams } from '@/core/models/User';
import { EMAIL_REGEX } from '@/core/regex';
import useResetPasswordMutation from '@/hooks/useResetPasswordMutation';

interface ResetPasswordFormProps {
  recaptchaToken: string;
  onResetSuccess(email: string): void;
  onResetError(): void;
}

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME !== 'production';

export const ResetPasswordForm = ({
  recaptchaToken,
  onResetSuccess,
  onResetError,
}: ResetPasswordFormProps) => {
  const { control, handleSubmit } = useForm<ResetPasswordParams>();
  const { mutateAsync: resetPasswordMutateAsync, isPending } = useResetPasswordMutation();

  const onSubmit: SubmitHandler<ResetPasswordParams> = (data) => {
    resetPasswordMutateAsync({ email: data.email, token: recaptchaToken })
      .then(() => {
        onResetSuccess(data.email);
        toastInfo(`Please check your email inbox to complete the process.`);
      })
      .catch(() => {
        toastError();
        onResetError();
      });
  };

  return (
    <form className="flex flex-col gap-y-3" onSubmit={handleSubmit(onSubmit)}>
      <ControlledInput
        label="Email"
        control={control}
        name="email"
        rules={{
          required: { value: true, message: 'This field is required.' },
          pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email.' },
        }}
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        color="primary"
        className="w-full"
        type="submit"
        loading={isPending}
      >
        Send reset password email
      </Button>
      <p>
        No account yet?{' '}
        <a
          className="text-blue-500 hover:text-blue-700"
          href={`https://app${IS_DEVELOPMENT ? `.${import.meta.env.VITE_ENV_NAME}` : ''}.easyship.com/signup`}
          rel="noreferrer"
        >
          Get started for free
        </a>
      </p>
    </form>
  );
};
