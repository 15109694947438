import Cookies from 'js-cookie';

import { API } from '@/utils/apiConstants';

const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

export const setCredentials = (token: string) => {
  if (Cookies.get(API.credentials)) removeCredentials();
  Cookies.set(API.credentials, token, {
    domain: cookieDomain,
    secure: true,
    sameSite: 'none',
    partitioned: true,
    path: '/',
  });
};

const removeCredentials = () => {
  Cookies.remove(API.credentials, {
    secure: true,
    sameSite: 'none',
    partitioned: true,
    domain: cookieDomain,
    path: '/',
  });
};

export const setMfaToken = (token: string) => {
  Cookies.set('mfaToken', token, {
    domain: cookieDomain,
    secure: true,
    sameSite: 'none',
    partitioned: true,
    path: '/',
  });
};

export const removeMfaToken = () => {
  Cookies.remove('mfaToken', {
    secure: true,
    sameSite: 'none',
    partitioned: true,
    domain: cookieDomain,
    path: '/',
  });
};

export const setRedirectBackUrl = (url: string) => {
  Cookies.set('redirectBackUrl', url, {
    domain: cookieDomain,
    secure: true,
    sameSite: 'none',
    partitioned: true,
    path: '/',
  });
};
