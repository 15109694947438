import { Pulse } from 'easyship-components';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useRedirectMutation from '@/pages/auth/redirect-login/hooks/useRedirectMutation';

export const RedirectLogin = () => {
  const { mutate: mutateRedirect } = useRedirectMutation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  // Token needed to automatically login
  const token = searchParams.get('jwt');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      try {
        mutateRedirect({ jwt: token });
      } catch {
        navigate('/login');
      }
    }
  }, [token, pathname, navigate, mutateRedirect]);

  return <Pulse show />;
};
